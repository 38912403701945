.stats.tab {

	.title {
		padding: 10px;
		margin-bottom: 0;

		span {
			color: var(--color-accent-primary);
		}
	}

	.slice {
		background: #1D1D27;
		display: flex;
		border-bottom: 1px solid rgba(#fff, 0.1);

		&:first-child {
			border-top: 1px solid rgba(#fff, 0.1);
		}

		.icon {
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #15151D;
			flex: 0 0 auto;

			svg {
				width: 23px;
				height: 23px;
			}
		}

		.amount {
			align-items: center;
			padding: 0 15px;
			flex: 1 1 100%;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}

		.currency {
			font-size: 9px;
			line-height: 9px;
			color: #FFFFFF;
		}

		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
			width: 100%;
		}

		.quantity {
			font-size: 10px;
			line-height: 12px;
			text-align: right;
			font-weight: bold;
			color: #FFFFFF;

			span.active {
				color: var(--color-accent-primary);
			}

			span.inactive {
				color: #464651;
			}
		}

		.stat-bar {
			font-size: 0;
			width: 100%;
			height: 8px;
		}
	}
}