.stat-bar {
	--foreground-color: var(--color-accent-primary);
	--background-color: rgba(0, 0, 0, 0.3);
	--text-color: var(--color-text);
	--display-percent: none;

	position: relative;
	overflow: hidden;
	border-radius: 3px;
	width: 100%;
	height: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--background-color);
	padding: 0 6px 0;

	font-size: 12px;
	font-weight: bold;
	z-index: 1;

	.stat-bar-inner {
		position: absolute;
		top: 0;
		left: 0;
		background: var(--foreground-color);
		height: 100%;
		z-index: 0;
		transition: width 0.6s ease;

		container-type: inline-size;
		container-name: stat-bar-inner;
	}

	&.instant {
		transition: width 0 ease;
	}

	.stat-bar-current-level,
	.stat-bar-next-level {
		position: relative;
		z-index: 2;
		color: var(--text-color);
	}

	.stat-bar-percent {
		position: absolute;
		top: 0;
		z-index: 2;
		padding: 0 5px;
		color: var(--text-color);
		display: var(--display-percent);
		line-height: 20px;

		@container stat-bar-inner (min-width: 4ch) {
			right: 0;
		}

		@container stat-bar-inner (max-width: 4ch) {
			left: 0;
		}
	}
}
