.streamer-card {
	display: flex;

	.avatar {
		--avatar-width: 40px;
		margin-right: 8px;
		flex: 0 0 auto;
	}

	.infos {
		font-family: 'Inter';
		font-style: normal;
		flex: 1 1 100%;

		.ctn {
			font-weight: 700;
			font-size: 8px;
			line-height: 12px;
			color: var(--color-accent-primary);
		}

		.name {
			font-weight: 700;
			font-size: 18px;
			line-height: 18px;
			color: var(--color-text-primary);
		}
	}
}