.give.tab {
	.slice {
		background: #1D1D27;
		display: flex;
		border-bottom: 1px solid rgba(#fff, 0.1);

		&:first-child {
			border-top: 1px solid rgba(#fff, 0.1);
		}

		.icon {
			width: 60px;
			height: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #15151D;
			flex: 0 0 auto;

			svg {
				width: 23px;
				height: 23px;
			}
		}

		.amount {
			align-items: center;
			padding: 0 15px;
			flex: 1 1 100%;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			text-transform: uppercase;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}

		.currency {
			font-size: 9px;
			line-height: 9px;
			color: #FFFFFF;
		}

		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 5px;
			width: 100%;
		}

		.quantity {
			font-size: 10px;
			line-height: 12px;
			text-align: right;
			font-weight: bold;
			color: #FFFFFF;

			span.active {
				color: var(--color-accent-primary);
			}

			span.inactive {
				color: #464651;
			}
		}

		.stat-bar {
			font-size: 0;
			width: 100%;
			height: 8px;
		}
	}

	.needs {
		display: flex;
		justify-content: space-between;

		.need {
			display: flex;
			flex-direction: column;
			flex: 1 1 100%;
			align-items: center;

			.resource {
				gap: 10px;
				margin-bottom: 10px;
				text-align: center;

				svg {
					width: 18px;
					height: 18px;
				}

				.amount {
					color: var(--color-accent-primary);
					font-weight: 700;
					line-height: 1;

					display: flex;
					gap: 10px;
					align-items: center;
				}

			}

			.item {
				text-align: center;
				font-size: 12px;

				small {
					font-size: 8px;
				}
			}
		}
	}

	.streamer-selection {
		padding: 10px;
		background-color: #101017;

		.streamer-select {
			display: flex;
			flex: 1 1 100%;
			background: #1D1D27;
			border-radius: 3px;
			padding: 0;
		}
	
		.streamer {
			padding: 6px 10px;
			flex: 1 1 100%;
		}
	
		.arrow-btn {
			width: 40px;
			align-self: stretch;
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 auto;
			border-left: 1px solid #101017;
		}
	}

	.back-btn-ctn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 18px;
		gap: 20px;
		background-color: #14141B;

		.back-btn {
			padding: 12px 0;
			width: 33%;
			background-color: #36363E;
		}

		.transfer-btn-ctn {
			position: relative;
			width: 66%;

			.spinner {
				position: absolute;
				top: 50%;
				right: 10px;
				width: 20px;
				height: 20px;
				transform: translate(0, -50%);
				opacity: 0;
				transition: opacity 0.3s ease;
			}

			&.is-transferring {
				opacity: 0.8;
				pointer-events: none;

				.spinner {
					opacity: 1;
				}
			}
		}

		.transfer-btn {
			padding: 12px 0;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			margin: auto 6px auto 0;
	
			&.disabled {
				opacity: 0.1;
				background-color: #000;
			}
		}
	}
}
