@at-root {
	:root {
		//General variables
		--color-text-primary: #fff;
		--color-text-secondary: white;
		--color-background-main: #000000;
		--color-accent-primary: #D25033;
		--color-accent-secondary: #455d7a;
		--font-family-title: 'Poppins', sans-serif;
	
		--color-button-text: var(--color-text-primary);
		--color-button-border: var(--color-accent-secondary);
		--color-button-background: var(--color-button-border);
	
		--container-size: 100%;
		--container-gutter: 0px;

		--nav-width: 100px;
	
		@include media-breakpoint-down(sm) {
			--container-gutter: 0px;
		}
	}
}

%inverted_colors {
	--color-text-primary: #e3e3e3;
	--color-background-main: #233142;
}
