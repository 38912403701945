.page.home {
	height: calc(var(--vh) * 100);
	
	.profile-button {
		position: absolute;
		top: 8px;
		left: 12px;

		.infos {
			filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
		}
	}

	.tabs {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;

		.tab-btns {
			display: flex;
			justify-content: flex-end;
			gap: 1px;
		}

		.tab-content {
			display: grid;
			height: var(--height);
			overflow: hidden;
			transition: height 0.3s ease;
			background: #101017;
		}

		.tab-btn {
			width: 60px;
			height: 60px;
			background: #1D1D27;
			display: flex;
			align-items: center;
			justify-content: center;

			transition: background-color 0.3s ease;

			svg {
				transition: opacity 0.3s ease;
			}

			&.stats {
				svg {
					width: 15px;
				}
			}

			&.sms {
				display: grid;

				svg {
					width: 30px;
					height: 30px;
					grid-row: 1;
					grid-column: 1;
					opacity: 1;
					transition: opacity 0.3s ease;
				}

				.spinner {
					grid-row: 1;
					grid-column: 1;
					opacity: 0;
					transition: opacity 0.3s ease;
				}

				&.disabled {
					svg {
						opacity: 0;
					}

					.spinner {
						opacity: 1;
					}
				}
			}

			&.give {
				svg {
					width: 30px;
				}
			}

			&.active {
				background: var(--color-accent-primary);

				svg {
					opacity: 1;
				}
			}
		}
	}

	&.enter,
	&.appear {
		.tabs {
			transform: translateY(110%);
		}

		&-active {
			.tabs {
				transform: translateY(0);
				transition: transform 0.6s $easeOutCubic;
			}
		}
	}
}

@import "./home/tab.scss";
@import "./home/give-tab.scss";
@import "./home/stats-tab.scss";