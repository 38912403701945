.circle-progress {
	--size: 100px;
	--stroke-width: 10px;
	--center: calc(var(--size) / 2);
	--radius: calc(var(--center) - var(--stroke-width));
	--stroke-track-color: #{rgba($white, 0.1)};
	--stroke-progress-color: var(--color-accent-primary);

	--progress: 0;

	--arc-length: calc(2 * var(--radius) * 3.141592);
	--arc-offset: calc(var(--arc-length) * ((100 - var(--progress)) / 100));

	width: var(--size);
	height: var(--size);
	transform: rotate(-90deg);
}

.circle-progress-track,
.circle-progress-indication {
	cx: var(--center);
	cy: var(--center);
	r: var(--radius);
	fill: transparent;
	stroke-width: var(--stroke-width);
}

.circle-progress-track {
	stroke: var(--stroke-track-color);
}

.circle-progress-indication {
	stroke: var(--stroke-progress-color);
	stroke-dasharray: var(--arc-length);
	stroke-dashoffset: var(--arc-offset);

	transition: stroke-dashoffset 0.3s $easeInOutCubic;
}