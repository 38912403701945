.parallax-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh) * 100);
	z-index: -1;
	
	.video-container {
		position: relative;
		width: 100vw;
		height: 100%;
		overflow: hidden;
	}
	
	.video {
		position: absolute;
		left: 50%;
		height: 100%;
		width: auto;
		max-width: none;

		&.feed-drop {
			opacity: 0;
			transition: opacity 1s ease;

			&.playing {
				opacity: 1;
			}
		}
	}
	
	.logo {
		position: absolute;
		top: 10vh;
		left: 50%;
		width: 50vw;
		transform: translateX(-50%);
		opacity: 0;
		filter: drop-shadow(0 0 50px rgba(0, 0, 0, 0.9));

		&.active {
			opacity: 1;
		}
	}

	&:not(.enter):not(.appear) {
		.logo {
			transition: opacity 0.6s ease;

			&.active {
				transition: opacity 0.6s ease 0.3s;
			}
		}
	}

	&.appear,
	&.enter {
		.logo {
			opacity: 0;
			transform: translate(-50%, 40px);
		}

		.motion-btn {
			opacity: 0;
		}

		&-active {
			.logo {
				opacity: 1;
				transform: translate(-50%, 0);
				transition: opacity 0.9s $easeOutCubic, transform 0.9s $easeOutCubic;
			}

			.motion-btn {
				opacity: 1;
				transition: opacity 0.6s $easeOutCubic 1s;
			}
		}
	}
}
	
.motion-btn {
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 10;
	opacity: 0.5;

	&.appear,
	&.enter {
		opacity: 0;

		&-active {
			opacity: 1;
			transition: opacity 0.6s $easeOutCubic 1s;
		}
	}
}