.profile.page {
	height: calc(var(--vh) * 100);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	.ctn {
		width: 100%;
		padding: 20px 30px;
		background-color: #14141B;
		text-align: center;

		input {
			background: transparent;
			border: none;
			border-bottom: 2px solid var(--color-accent-primary);
			outline: none;
			color: white;
			min-width: 5ch;
			width: 5ch;
			text-align: center;

			transition: width 0.1s ease;
		}

		.button {
			margin-top: 20px;
			padding: 12px 0;
			width: 130px;
		}
	}

	&.enter,
	&.appear {
		.ctn {
			transform: translateY(110%);
		}

		&-active {
			.ctn {
				transform: translateY(0);
				transition: transform 0.6s $easeOutCubic;
			}
		}
	}
}