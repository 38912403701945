.notifications-list {
	--speed: 0.6s;
	--list-width: 100vw;
	--list-padding: 0 0 0 0;

	--margin-top: 10px;
	--margin-bottom: 300px;

	position: fixed;
	top: 0;
	left: 0;
	padding: var(--list-padding);
	width: var(--list-width);
	max-height: calc(100vh - var(--margin-top) - var(--margin-bottom));
	overflow: hidden auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	scroll-behavior: smooth;
	z-index: 100;

	transform-origin: top right;
	transform: scale(var(--app-scale, 1));

	.close {
		display: flex;
		align-self: stretch;
		width: 65px;
		height: 65px;
		font-size: 32px;
		justify-content: center;
		margin-left: auto;
		line-height: 65px;
		font-weight: 900;
		background: rgba(#000, 0.3);
	}

	&.top-left {
		top: var(--margin-top);
		left: 0;
	}

	&.top {
		top: var(--margin-top);
		left: 0;
	}

	&.top-right {
		top: var(--margin-top);
		right: 0;
	}

	&.bottom-left {
		bottom: var(--margin-bottom);
		left: 0;
	}

	&.bottom-right {
		bottom: var(--margin-bottom);
		right: 0;
	}

	&.top-left,
	&.bottom-left {
		.notification {
			animation: notification-in-left var(--speed) $easeOutCubic;
			
			&.out {
				animation: notification-out-left calc(var(--speed) / 2) $easeInCubic forwards;
			}
		}
	}

	&.top-right,
	&.bottom-right  {
		.notification {
			animation: notification-in-right var(--speed) $easeOutCubic;
			
			&.out {
				animation: notification-out-right calc(var(--speed) / 2) $easeInCubic forwards;
			}
		}
	}

	&.top {
		.notification {
			animation: notification-in-right var(--speed) $easeOutCubic;
			
			&.out {
				animation: notification-out-right calc(var(--speed) / 2) $easeInCubic forwards;
			}
		}
	}

	.notifications-list .notification {
		transition: transform var(--speed);
	}
}

.notification {
	position: relative;
	display: flex;
	overflow: hidden;
	cursor: pointer;
	background: #1D1D27;
	width: 90%;

	.content {
		padding: 15px;
	}

	.title {
		font-weight: 700;
		font-size: 17px;
		line-height: 115%;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.description {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.05em;
	}
}

@keyframes notification-in-right {
	from {
		transform: translate(110%, 0);
	}
	to {
		transform: translate(0, 0);
	}
}

@keyframes notification-in-left {
	from {
		transform: translate(-110%, 0);
	}
	to {
		transform: translate(0, 0);
	}
}

@keyframes notification-in-top {
	from {
		transform: translate(0, -110%);
	}
	to {
		transform: translate(0, 0);
	}
}

@keyframes notification-out-right {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(110%, 0);
	}
}

@keyframes notification-out-left {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(-110%, 0);
	}
}

@keyframes notification-out-top {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate(0, -110%);
	}
}
