.profile-button {
	position: relative;
	margin-top: auto;
	text-transform: uppercase;
	font-size: 14px;
	text-decoration: none;
	line-height: 1;
	margin-bottom: 5px;
	display: flex;
	align-items: center;

	.avatar {
		--avatar-width: 40px;
		transition: transform 0.3s ease;
		transform: scale(1);
		justify-content: center;
		flex: 0 0 auto;
		margin: 0 8px 0 0;
	}

	.infos {
		.level {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 700;
			font-size: 8px;
			line-height: 16px;
			color: #D25033;
			margin-top: -4px;
		}

		.display-name {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 16px;
			color: #FFFFFF;
		}
	}
}