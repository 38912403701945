.page.intro {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(var(--vh) * 100);
	z-index: 1;

	.video-container {
		position: relative;
		width: 100vw;
		height: 100%;
		overflow: hidden;
	}

	.video {
		position: absolute;
		left: 50%;
		height: 100%;
		width: auto;
		max-width: none;
		transform: translate(-50%, 0);
	}
}
