.slider {
	position: relative;
	width: 100%;
	
	.knob {
		--width: 10px;
		position: absolute;
		top: 50%;
		left: var(--x, 0px);
		width: var(--width);
		height: calc(100% + 4px);
		border-radius: 3px;
		background: white;
		z-index: 2;
		transform: translate(0, -50%);

		// transition: left 0.3s ease;
	}

	.stat-bar {
		.stat-bar-inner {
			transition: none;
		}
	}

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		opacity: 0;
	}
}