

.tab {
	max-height: 0;
	// overflow: hidden;
	grid-row: 1;
	grid-column: 1;
	align-self: flex-start;
	position: relative;

	pointer-events: none;
	opacity: 0;

	&.active {
		pointer-events: all;
	}

	.content {
		background: #101017;
	}

	.title {
		font-size: 12px;
		line-height: 15px;
		color: #FFFFFF;
		margin-bottom: 10px;
		text-transform: uppercase;
		font-weight: 700;

		&.streamer-needs {
			margin-top: 10px;
		}

		&.resources {
			margin-bottom: 0;
		}
	}

	&.enter,
	&.appear {
		max-height: 0;
		opacity: 0;
		
		&-active {
			opacity: 1;
			max-height: var(--height, auto);
			transition: opacity 0.3s $easeInOutCubic 0.3s;
		}

		&-done {
			opacity: 1;
			max-height: var(--height, auto);
		}
	}

	&.exit {
		opacity: 1;
		max-height: var(--height, auto);
		
		&-active {
			opacity: 0;
			max-height: 0;
			transition: opacity 0.3s $easeInOutCubic;
		}

		&-done {
			opacity: 0;
			max-height: 0;
		}
	}
}