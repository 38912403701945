.level-badge {
	display: inline-block;
	background: rgba($black, 0.8);
	padding: 4px 6px;
	color: var(--color, var(--color-accent-primary));
	font-weight: $font-weight-bold;
	font-size: 12px;
	line-height: 1;
	border-radius: 4px;
	text-transform: none;
}